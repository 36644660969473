/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {GranularKinderOrtPermissionType} from '@dv/shared/backend/model/granular-kinder-ort-permission-type';
import type {IPersistable, IRestModel} from '@dv/shared/code';

export class GranularKinderOrtPermission implements IRestModel, IPersistable {

    public constructor(
        public id: string | null = null,
        public benutzerId: string | null = null,
        public kinderOrtNamesById: { id: string; text: string } [] = [],
        public permission: GranularKinderOrtPermissionType | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): GranularKinderOrtPermission {
        const kinderOrtNamesById = Object.entries(data.kinderOrtNamesById)
            .map(([id, text]) => {
                return {id, text: text as string};
            });

        return new GranularKinderOrtPermission(
            data.id,
            data.benutzerId,
            kinderOrtNamesById,
            data.permission,
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            benutzerId: this.benutzerId,
            kinderOrtIds: this.kinderOrtNamesById.map(entry => entry.id),
            permission: this.permission,
        };
    }
}

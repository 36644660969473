/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IDisplayable, TimeRange} from '@dv/shared/code';
import type {WorkTimePlannedTimeType} from './WorkTimePlannedTimeType';

export class WorkTimePlannedTime implements IDisplayable {
    public constructor(
        public name: string,
        public type: WorkTimePlannedTimeType,
        public timeRange: TimeRange,
    ) {
    }

    public getDisplayName(): string {
        return this.name;
    }
}

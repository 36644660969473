/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxApplicationRole} from '@dv/shared/backend/model/jax-application-role';
import type {KitaPermissionSet} from '@dv/shared/backend/model/kita-permission-set';
import type {PersonalPermissionSet} from '@dv/shared/backend/model/personal-permission-set';
import type {WorkTimeControllingPermissionSet} from '@dv/shared/backend/model/work-time-controlling-permission-set';
import type {IRestModel, IValidable} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';
import type {UserRole} from '@dv/shared/roles';
import {isKitaPermissionSet, isPersonalPermissionSet} from '@dv/shared/roles';

export class ApplicationRole implements IRestModel<JaxApplicationRole>, IValidable {

    public constructor(
        public userRole: UserRole | null = null,
        public kitaPermissionSet: KitaPermissionSet | null = null,
        public personalPermissionSet: PersonalPermissionSet | null = null,
        public workTimeControllingPermissionSet: WorkTimeControllingPermissionSet | null = null,
    ) {
    }

    public static apiResponseTransformer(data: JaxApplicationRole): ApplicationRole {
        const kitaPermissionSet = isKitaPermissionSet(data.kitaPermissionSet) ? data.kitaPermissionSet : null;
        const personalPermissionSet = isPersonalPermissionSet(data.personalPermissionSet) ?
            data.personalPermissionSet :
            null;
        const workTimeControllingPermissionSet = data.workTimeControllingPermissionSet ?? null;

        return new ApplicationRole(
            data.userRole,
            kitaPermissionSet,
            personalPermissionSet,
            workTimeControllingPermissionSet);
    }

    public isValid(): boolean {
        const hasKinderOrtRole = isPresent(this.kitaPermissionSet)
            || isPresent(this.personalPermissionSet)
            || isPresent(this.workTimeControllingPermissionSet);
        const hasUserRole = isPresent(this.userRole);

        return hasUserRole !== hasKinderOrtRole;
    }

    public toRestObject(): JaxApplicationRole {
        return {
            userRole: this.userRole ?? undefined,
            kitaPermissionSet: this.kitaPermissionSet ?? undefined,
            personalPermissionSet: this.personalPermissionSet ?? undefined,
            workTimeControllingPermissionSet: this.workTimeControllingPermissionSet ?? undefined,
        };
    }
}

/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import type {IDisplayable, Persisted} from '@dv/shared/code';
import {isPresent} from '@dv/shared/code';

export class WorkTimeModel implements Omit<JaxReadWorkTimeModel, 'ferienKontingente'>, IDisplayable {
    public constructor(
        public id: EntityId,
        public weeklyHours: number,
        public name?: string,
        public mandantDefault?: boolean,
    ) {
    }

    public static apiResponseTransformer(data: JaxReadWorkTimeModel): Persisted<WorkTimeModel> {
        return new WorkTimeModel(
            data.id,
            data.weeklyHours,
            data.name,
            data.mandantDefault,
        );
    }

    public getDisplayName(): string {
        return isPresent(this.name) && this.name !== '' ? `${this.name} (${this.weeklyHours})` : `${this.weeklyHours}`;
    }
}
